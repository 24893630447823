<template>
  <b-table
    :items="items"
    :fields="fields"
    :busy="fetchingOrders"
    :sort-compare-options="{ ignorePunctuation: true, numeric: true }"
    borderless
    sticky-header="30vh"
    responsive="true"
    class="m-table__wrapper"
    empty-text="No results"
    show-empty
    table-class="m-table"
    thead-class="m-table__header"
    thead-tr-class="m-table__header-tr"
    tbody-class="m-table__body"
    tbody-tr-class="m-table__body-tr"
  >
    <template #cell(products)="{ item, value }">
      <div>
        <span v-if="value.length === 0">/</span>
        <span v-else-if="value.length === 1"
          >{{ value[0].name }} <br />
          <span class="font-weight-bold">Quantity:</span> x{{
            value[0].quantity
          }}</span
        >
        <b-button v-else variant="secondary" @click="showProducts(item)"
          >Show all products ({{ value.length }})</b-button
        >
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "OrdersTable",
  data() {
    return {
      fields: [
        {
          key: "submittedAt",
          sortable: true,
          tdClass: "date",
          thClass: "date",
          formatter: (value) => {
            return this.$options.filters.date(value);
          },
        },
        {
          key: "refNumber",
          label: "Reference number",
          sortable: true,
          tdClass: "ref",
          thClass: "ref",
        },
        {
          key: "store",
          sortable: true,
          tdClass: "store",
          thClass: "store",
          formatter: (value) => {
            return value || "Manually added";
          },
        },
        {
          key: "products",
          sortable: true,
          tdClass: "products",
          thClass: "products",
        },
        {
          key: "finalPrice",
          sortable: true,
          tdClass: "price",
          thClass: "price",
          formatter: (value) => {
            return (value / 100).toFixed(2) + "€";
          },
        },
        {
          key: "status",
          sortable: true,
          tdClass: "status",
          thClass: "status",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.clientInfo.orders;
    },
    fetchingOrders() {
      return this.$store.state.clientInfo.requesting;
    },
  },
  methods: {
    showProducts(order) {
      this.$store.commit("clientInfo/SET_SELECTED_ORDER", order);
      this.$bvModal.show("show-products");
    },
  },
};
</script>
