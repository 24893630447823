<template>
  <b-table
    :items="items"
    :fields="fields"
    :busy="fetchingLogs"
    :sort-compare-options="{ ignorePunctuation: true, numeric: true }"
    borderless
    sticky-header="30vh"
    responsive="true"
    empty-text="No results"
    show-empty
    class="m-table__wrapper"
    table-class="m-table"
    thead-class="m-table__header"
    thead-tr-class="m-table__header-tr"
    tbody-class="m-table__body"
    tbody-tr-class="m-table__body-tr"
  >
    <template #cell(action.value)="{ value, item }">
      <div class="d-flex justify-content-between">
        {{ value }}
        <span v-if="item.comment.length > 0" v-b-tooltip="item.comment"
          ><i class="fas fa-info-circle"></i
        ></span>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "TokenLogsTable",
  data() {
    return {
      fields: [
        {
          key: "date",
          sortable: true,
          tdClass: "date",
          thClass: "date",
          formatter: (value) => {
            return this.$options.filters.date(value);
          },
        },
        {
          key: "action.type",
          label: "Action",
          sortable: true,
          tdClass: "action",
          thClass: "action",
        },
        {
          key: "action.value",
          label: "Products/Codes/Promotions",
          sortable: true,
          tdClass: "action-value",
          thClass: "action-value",
        },
        {
          key: "changeValue",
          label: "Status change",
          sortable: true,
          tdClass: "changes",
          thClass: "changes",
        },
        {
          key: "oldValue",
          label: "Status after change",
          sortable: true,
          tdClass: "tokens",
          thClass: "tokens",
          formatter: (value, key, item) => {
            return value + item.changeValue;
          },
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.clientInfo.tokenLogs;
    },
    fetchingLogs() {
      return this.$store.state.clientInfo.requesting;
    },
  },
};
</script>

<style scoped></style>
