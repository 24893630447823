<template>
  <b-modal
    id="client-info"
    centered
    size="xl"
    modal-class="m-modal"
    header-class="m-modal__header"
    footer-class="m-modal__footer"
    body-class="m-modal__body"
    @show="getData"
    @hidden="resetData"
  >
    <template #modal-header>
      <h4 class="m-modal__title">Client loyalty pointsa</h4>
    </template>
    <p class="d-flex align-items-center info-box info">
      <i class="fas fa-info-circle icon mr-3"></i>
      Below we can see the basic operational information of the client
    </p>
    <p class="d-flex flex-wrap mb-2">
      <span class="info-label">Client: </span><br />
      <span class="font-weight-bold text-primary-color ml-2">
        {{ `${client.firstName} ${client.lastName}, ${client.email}` }}</span
      >
    </p>
    <p class="d-flex flex-wrap mb-2">
      <span class="info-label">Address: </span><br />
      <span class="font-weight-bold text-primary-color ml-2">{{
        client.address || "/"
      }}</span>
    </p>
    <div class="d-flex align-items-center mb-4">
      <span class="info-label">Current Status: </span><br />
      <div class="d-flex align-items-center">
        <img
          :src="require('@/assets/images/butterlfy-icon.png')"
          class="token-icon mx-1"
          v-b-tooltip.hover="'Number of butterflies'"
        />
        x {{ client.tokenValue }}
      </div>
    </div>
    <table-navigation />
    <component :is="tableComponent" />
    <table-pagination />
    <template #modal-footer="{ close }">
      <b-button variant="secondary" class="col-2" @click="close"
        >Close</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import OrdersTable from "./client-info/OrdersTable";
import TokenLogsTable from "./client-info/TokenLogsTable";
import TableNavigation from "./client-info/TableNavigation";
import TablePagination from "./client-info/TablePagination";
export default {
  name: "ClientInfo",
  components: { TablePagination, TableNavigation, TokenLogsTable, OrdersTable },
  computed: {
    client() {
      return this.$store.state.client.client;
    },
    tableComponent() {
      return this.$store.state.clientInfo.tableComponent;
    },
  },
  methods: {
    async getData() {
      await this.$store.dispatch("clientInfo/getClientOrders");
    },
    resetData() {
      this.$store.commit("clientInfo/RESET_STATE");
    },
  },
};
</script>

<style scoped></style>
