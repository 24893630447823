<template>
  <div class="d-flex justify-content-end">
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      pills
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "TablePagination",
  computed: {
    currentTable() {
      return this.$store.state.clientInfo.tableComponent;
    },
    currentTableFetchingAction() {
      return this.currentTable === "orders-table"
        ? "getClientOrders"
        : "getClientTokenLogs";
    },
    currentPage: {
      get() {
        return this.$store.state.clientInfo.currentPage;
      },
      set(value) {
        this.$store.commit("clientInfo/SET_CURRENT_PAGE", value);
        this.$store.dispatch("clientInfo/" + this.currentTableFetchingAction);
      },
    },
    totalRows() {
      return this.$store.state.clientInfo.totalRows;
    },
    perPage() {
      return this.$store.state.clientInfo.perPage;
    },
  },
};
</script>

<style scoped></style>
