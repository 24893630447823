<template>
  <b-modal
    id="show-products"
    centered
    size="xl"
    modal-class="m-modal"
    header-class="m-modal__header"
    footer-class="m-modal__footer"
    body-clas="m-modal__body"
  >
    <template #modal-header>
      <h4 class="m-modal__title">Order: {{ order.refNumber }}</h4>
    </template>
    <p class="d-flex align-items-center info-box info">
      <i class="fas fa-info-circle icon mr-3"></i>
      <span
        >A list of all products bought on <b>{{ submittedAt }}</b
        >, with reference number <b>{{ order ? order.refNumber : "" }}</b
        >.</span
      >
    </p>
    <div class="mt-3">
      <b-input-group class="col-4 px-0 mb-2 ml-auto">
        <b-input-group-append>
          <div class="m-input__icon left">
            <i class="far fa-search"></i>
          </div>
        </b-input-group-append>
        <b-form-input
          id="search"
          v-model="searchQuery"
          type="text"
          size="sm"
          placeholder="Search by products"
          class="border-left-0 pl-0 py-2"
        ></b-form-input>
      </b-input-group>
      <b-table
        :items="products"
        :fields="fields"
        :sort-compare-options="{ ignorePunctuation: true, numeric: true }"
        :filter="searchQuery"
        borderless
        sticky-header="30vh"
        responsive="true"
        class="m-table__wrapper"
        table-class="m-table"
        thead-class="m-table__header"
        thead-tr-class="m-table__header-tr"
        tbody-class="m-table__body"
        tbody-tr-class="m-table__body-tr"
      >
        <template #cell(tokens)="{ value }">
          <div class="d-flex align-items-center font-weight-bold">
            <img
              :src="require('@/assets/images/butterlfy-icon.png')"
              class="token-icon mr-2"
              v-b-tooltip.hover="'Number of butterflies'"
            />
            x {{ value }}
          </div>
        </template>
      </b-table>
    </div>
    <template #modal-footer="{ close }">
      <b-button variant="secondary" class="col-2" @click="close"
        >Close</b-button
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ShowAllProducts",
  data() {
    return {
      searchQuery: "",
      fields: [
        {
          key: "name",
          sortable: true,
        },
        {
          key: "sku",
          sortable: true,
        },
        {
          key: "quantity",
          sortable: true,
        },
        {
          key: "base_price",
          sortable: true,
          formatter: (value) => {
            return (value / 100).toFixed(2) + "€";
          },
          filterByFormatted: true,
        },
        {
          key: "discount",
          sortable: true,
          formatter: (value) => {
            return value > 0 ? value + "%" : "-";
          },
          filterByFormatted: true,
        },
        {
          key: "price",
          sortable: true,
          formatter: (value) => {
            return (value / 100).toFixed(2) + "€";
          },
          filterByFormatted: true,
        },
        {
          key: "tokens",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    order() {
      return this.$store.state.clientInfo.selectedOrder;
    },
    submittedAt() {
      if (this.order) return this.$options.filters.date(this.order.submittedAt);
      return "/";
    },
    products() {
      return this.order ? this.order.products : [];
    },
  },
};
</script>
