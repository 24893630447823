<template>
  <div class="p-4">
    <clients-search />
    <clients-table />
    <clients-pagination />
    <edit-tokens />
    <client-info />
    <show-all-products />
  </div>
</template>

<script>
import ClientsSearch from "../components/clients/ClientsSearch";
import ClientsTable from "../components/clients/ClientsTable";
import ClientsPagination from "../components/clients/ClientsPagination";
import EditTokens from "../components/clients/modals/EditTokens";
import ClientInfo from "../components/clients/modals/ClientInfo";
import ShowAllProducts from "../components/clients/modals/client-info/ShowAllProducts";
export default {
  name: "MClients",
  components: {
    ShowAllProducts,
    ClientInfo,
    EditTokens,
    ClientsPagination,
    ClientsTable,
    ClientsSearch,
  },
  async created() {
    try {
      this.$store.commit("CHANGE_SCREEN_LOADING_STATUS", false);
      const res = await this.$axios.get("/balance-log-categories");
      this.$store.commit("clientsTable/SET_CAUSE_CATEGORIES", res.data.data);
    } catch (e) {
      console.error(e);
    } finally {
      this.$store.commit("CHANGE_SCREEN_LOADING_STATUS", false);
    }
  },
};
</script>

<style scoped></style>
