<template>
  <b-modal
    id="client-tokens"
    centered
    size="lg"
    modal-class="m-modal"
    header-class="m-modal__header"
    footer-class="m-modal__footer"
    body-class="m-modal__body"
    @hidden="resetData"
  >
    <template #modal-header>
      <h4 class="m-modal__title">Client loyalty points</h4>
    </template>
    <p class="d-flex align-items-center info-box info">
      <i class="fas fa-info-circle icon mr-3"></i>
      <span>
        You can use the following input fields to edit client's loyalty points.
        <b> Changes will take effect immediately!</b>
      </span>
    </p>
    <p class="d-flex flex-wrap mb-2">
      <span class="info-label">Client: </span><br />
      <span class="font-weight-bold text-primary-color ml-2">
        {{ `${client.firstName} ${client.lastName}, ${client.email}` }}</span
      >
    </p>
    <p class="d-flex align-items-center flex-wrap mb-2">
      <span class="info-label">Current status: </span><br />
      <span class="font-weight-bold text-primary-color ml-2">
        <img
          :src="require('@/assets/images/butterlfy-icon.png')"
          class="token-icon"
          v-b-tooltip.hover="'Number of butterflies'"
        />
        x{{ client.tokenValue }}</span
      >
    </p>
    <validation-observer
      ref="clientTokens"
      tag="div"
      class="d-flex flex-column mt-4"
    >
      <div class="d-flex">
        <div class="d-flex flex-column">
          <span class="m-input__label is-required">Enter the token value</span>
          <div class="d-flex align-items-center">
            <b-form-select
              size="sm"
              v-model="operationType"
              class="col-5"
              :disabled="requesting"
            >
              <b-form-select-option value="1">Add (+)</b-form-select-option>
              <b-form-select-option value="-1">Take (-) </b-form-select-option>
            </b-form-select>
            <validation-provider
              tag="div"
              name="tokens"
              rules="required|min_value:1"
              class="d-flex flex-column col-3 px-1"
              v-slot="{ errors }"
            >
              <b-form-input
                v-model="tokenValue"
                type="number"
                size="sm"
                no-wheel
                number
                :disabled="requesting"
              ></b-form-input>
              <span class="text-danger error">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <div class="d-flex flex-column">
          <span class="m-input__label">New status</span>
          <div class="d-flex align-items-center">
            <img
              :src="require('@/assets/images/butterlfy-icon.png')"
              class="token-icon"
              v-b-tooltip.hover="'Number of butterflies'"
            />
            x {{ newTokenValue }}
          </div>
        </div>
      </div>
      <validation-provider
        tag="div"
        name="cause"
        rules="required"
        class="d-flex flex-column mt-3 col-6 px-0"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Select cause</span>
        <b-form-select v-model="cause" size="sm" :disabled="requesting">
          <b-form-select-option
            v-for="cause in causes"
            :key="cause.id"
            :value="cause.id"
            :disabled="requesting"
            >{{ cause.name }}
          </b-form-select-option>
        </b-form-select>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <validation-provider
        tag="div"
        name="comment"
        rules="required"
        class="d-flex flex-column mt-3 col-12 px-0"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Comment</span>
        <b-form-textarea v-model="comment" size="sm" :disabled="requesting" />
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
    </validation-observer>
    <template #modal-footer="{ close }">
      <div class="d-flex justify-content-between align-items-center w-100">
        <b-form-checkbox v-model="notify"
          >Sent email notification
        </b-form-checkbox>
        <div class="d-flex justify-content-end align-items-center w-50">
          <b-button
            variant="secondary"
            class="col-3 mr-2"
            :disabled="requesting"
            @click="close"
            >Close
          </b-button>
          <b-button
            variant="primary"
            class="col-4"
            :disabled="requesting"
            @click="updateUserTokens"
            >Save
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "EditTokens",
  data() {
    return {
      tokenValue: 0,
      cause: null,
      comment: "",
      operationType: 1,
      requesting: false,
      notify: false,
    };
  },
  computed: {
    causes() {
      return this.$store.state.clientsTable.causeCategories;
    },
    client() {
      return this.$store.state.client.client;
    },
    newTokenValue() {
      return this.operationType > 0
        ? Number(this.client.tokenValue) + Number(this.tokenValue)
        : Number(this.client.tokenValue) - Number(this.tokenValue);
    },
  },
  methods: {
    updateUserTokens() {
      this.$refs.clientTokens.validate().then(async (success) => {
        if (success) {
          try {
            this.requesting = true;
            const res = await this.$store.dispatch(
              "client/updateClientTokens",
              {
                value: Number(this.tokenValue) * this.operationType,
                logCategoryId: this.cause,
                comment: this.comment,
                notifyClient: this.notify,
              }
            );
            this.$store.commit(
              "clientsTable/UPDATE_CLIENT_DATA",
              res.data.data
            );
            this.$bvModal.hide("client-tokens");
            this.$bvToast.toast("Client tokens updated successfully", {
              title: "Client tokens",
              variant: "success",
            });
          } catch (e) {
            console.error(e);
            this.$bvToast.toast(
              "There was and error updating the client tokens",
              {
                title: "Client tokens",
                variant: "danger",
              }
            );
          } finally {
            this.requesting = false;
          }
        }
      });
    },
    resetData() {
      this.tokenValue = 0;
      this.cause = null;
      this.comment = "";
      this.operationType = 1;
      this.requesting = false;
      this.$store.commit("SET_USER_DATA", {});
    },
  },
};
</script>

<style scoped></style>
