<template>
  <b-table
    :items="items"
    :fields="fields"
    :busy="fetchingClients"
    :sort-compare-options="{ ignorePunctuation: true, numeric: true }"
    borderless
    sticky-header="72vh"
    responsive="true"
    class="m-table__wrapper"
    table-class="m-table"
    thead-class="m-table__header"
    thead-tr-class="m-table__header-tr"
    tbody-class="m-table__body"
    tbody-tr-class="m-table__body-tr"
  >
    <template #cell(tokenValue)="{ value }">
      <span class="mr-3">
        <img
          :src="require('@/assets/images/butterlfy-icon.png')"
          class="token-icon"
          v-b-tooltip.hover="'Number of butterflies'"
        />
        x{{ value }}
      </span>
    </template>
    <template #cell(actions)="{ item }">
      <div class="d-flex align-items-center">
        <b-button
          variant="action"
          v-b-tooltip.hover="'Edit number of butterflies'"
          class="mr-2"
          @click="editUserTokens(item)"
          ><i class="fad fa-pencil-alt"></i
        ></b-button>
        <b-button
          variant="action"
          v-b-tooltip.hover="'Information of purchases and token changes'"
          @click="viewInfo(item)"
          ><i class="fas fa-info-circle"></i
        ></b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "ClientsTable",
  data() {
    return {
      fields: [
        {
          key: "firstName",
          sortable: true,
          tdClass: "name",
          thClass: "name",
        },
        {
          key: "lastName",
          sortable: true,
          tdClass: "surname",
          thClass: "surname",
        },
        {
          key: "email",
          sortable: true,
          tdClass: "email",
          thClass: "email",
        },
        {
          key: "registerDate",
          sortable: true,
          tdClass: "date-registered",
          thClass: "date-registered",
          formatter: (value) => {
            return this.$options.filters.date(value);
          },
        },
        {
          key: "deletedAt",
          label: "Deleted At",
          sortable: true,
          tdClass: "date-signed",
          thClass: "date-signed",
          formatter: (value) => {
            if (value === null) return "/";
            return this.$options.filters.date(value);
          },
        },
        {
          key: "tokenValue",
          label: "Loyalty points",
          sortable: true,
          tdClass: "tokens",
          thClass: "tokens",
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.clientsTable.clients;
    },
    fetchingClients() {
      return this.$store.state.clientsTable.requesting;
    },
  },
  async created() {
    await this.getItems();
  },
  watch: {
    searchQuery() {
      this.getItems();
    },
  },
  methods: {
    async getItems() {
      await this.$store.dispatch("clientsTable/getClients");
    },
    editUserTokens(client) {
      this.$store.commit("client/SET_CLIENT_DATA", client);
      this.$bvModal.show("client-tokens");
    },
    viewInfo(client) {
      this.$store.commit("client/SET_CLIENT_DATA", client);
      this.$store.commit("clientInfo/SET_TABLE_DATA", {
        tableName: "orders",
        data: client.orders,
      });
      this.$bvModal.show("client-info");
    },
  },
};
</script>

<style scoped></style>
