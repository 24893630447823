<template>
  <div class="d-flex justify-content-end">
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      pills
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "ClientsPagination",
  computed: {
    currentPage: {
      get() {
        return this.$store.state.clientsTable.currentPage;
      },
      set(value) {
        this.$store.commit("clientsTable/SET_CURRENT_PAGE", value);
        this.$store.dispatch("clientsTable/getClients");
      },
    },
    totalRows() {
      return this.$store.state.clientsTable.totalRows;
    },
    perPage() {
      return this.$store.state.clientsTable.perPage;
    },
  },
};
</script>

<style scoped></style>
