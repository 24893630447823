<template>
  <div class="d-flex justify-content-between align-items-end">
    <b-button-group>
      <b-button
        :variant="
          currentTable === 'orders-table' ? 'secondary' : 'outline-secondary'
        "
        class="border-bottom-left-0 border-bottom-0"
        @click="currentTable = 'orders-table'"
        >Purchases</b-button
      >
      <b-button
        :variant="
          currentTable === 'token-logs-table'
            ? 'secondary'
            : 'outline-secondary'
        "
        class="border-bottom-right-0 border-bottom-0"
        @click="currentTable = 'token-logs-table'"
        >Token changes</b-button
      >
    </b-button-group>
    <div class="d-flex justify-content-end align-items-center mb-2">
      <v-date-picker
        v-model="dateRange"
        color="red"
        is-range
        class="mr-2 col-5 px-0"
      >
        <template v-slot="{ inputValue, togglePopover }">
          <b-input-group>
            <b-input-group-append>
              <div class="m-input__icon left">
                <i class="far fa-calendar-day"></i>
              </div>
            </b-input-group-append>
            <b-form-input
              id="dateRange"
              type="text"
              size="sm"
              class="border-left-0 border-right-0 px-0 py-2"
              readonly
              :value="inputValue.start ? getDate(inputValue) : 'Filter by date'"
              @focus="togglePopover"
              @blur="togglePopover"
            ></b-form-input>
            <b-input-group-prepend>
              <div class="m-input__icon right show-cursor" @click="clearDate">
                <i class="fal fa-times"></i>
              </div>
            </b-input-group-prepend>
          </b-input-group>
        </template>
      </v-date-picker>
      <b-input-group class="col-5 px-0 mr-2">
        <b-input-group-append
          ><div class="m-input__icon left">
            <i class="far fa-search"></i></div
        ></b-input-group-append>
        <b-form-input
          id="search"
          v-model="searchQuery"
          type="text"
          size="sm"
          placeholder="Search by (code, ref. number, date...)"
          class="border-left-0 pl-0 py-2"
          debounce="500"
        ></b-form-input>
      </b-input-group>
      <b-button
        variant="primary"
        class="col-3"
        :disabled="downloading"
        @click="exportCsv"
        >Export to csv</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "TableNavigation",
  data() {
    return {
      downloading: false,
    };
  },
  computed: {
    clientId() {
      return this.$store.state.client.client.id;
    },
    currentTable: {
      get() {
        return this.$store.state.clientInfo.tableComponent;
      },
      set(value) {
        this.$store.commit("clientInfo/CHANGE_TABLE_COMPONENT", value);
        this.$store.commit("clientInfo/SET_CURRENT_PAGE", 1);
        this.searchQuery = "";
      },
    },
    exportType() {
      return this.currentTable === "orders-table" ? "orders" : "tokens";
    },
    currentTableFetchingAction() {
      return this.currentTable === "orders-table"
        ? "getClientOrders"
        : "getClientTokenLogs";
    },
    searchQuery: {
      get() {
        return this.$store.state.clientInfo.searchQuery;
      },
      set(value) {
        this.$store.commit("clientInfo/SET_SEARCH_QUERY", value);
        this.$store.dispatch("clientInfo/" + this.currentTableFetchingAction);
      },
    },
    dateRange: {
      get() {
        return this.$store.state.clientInfo.dateRange;
      },
      set(value) {
        this.$store.commit("clientInfo/SET_DATE_RANGE", value);
        this.$store.dispatch("clientInfo/" + this.currentTableFetchingAction);
      },
    },
  },
  methods: {
    async exportCsv() {
      try {
        this.downloading = true;
        const res = await this.$axios.get(
          `/clients/${this.clientId}/export?type=${this.exportType}`,
          { responseType: "blob" }
        );
        this.downloadFile(res.data, this.exportType);
      } catch (e) {
        console.error(e);
      } finally {
        this.downloading = false;
      }
    },
    getDate(dateRange) {
      return `${this.$options.filters.date(
        dateRange.start
      )} - ${this.$options.filters.date(dateRange.end)}`;
    },
    clearDate() {
      this.dateRange = {
        start: null,
        end: null,
      };
    },
  },
};
</script>

<style scoped>
.border-bottom-left-0 {
  border-bottom-left-radius: 0;
}
.border-bottom-right-0 {
  border-bottom-right-radius: 0;
}
</style>
